@import './../../../styles/settings.scss';

.fab__bound {
  display: flex;
  align-items: center;
}

.fab__label {
  color: $black-light;
  margin-right: 1rem;
}

.fab__button {
  background: $p110;
  appearance: none;
  border: 0;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  transition: all ease-in 200ms;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.24),
    0px 0px 8px rgba(0, 0, 0, 0.12),
    0px 1px 0px rgba(255, 255, 255, 0.12);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: $p120;
  }
}


// Tables

.table__button {
  width: 2.5rem;
  height: 2.5rem;
  border: 1px solid #007AFF;
  background: none;
  border-radius: .5rem;
  appearance: none;
  margin-left: .5rem;
  cursor: pointer;
  &:hover {
    background: rgba(0,100,205, .2);
  }
}

.table__head {
  height: 4rem;
  flex-grow: 1;
  text-align: left;
  align-items: center;
  padding: .5rem;
  display: flex;
  background: $p100;
  p {
    font-size: 1rem;
    font-weight: $bold;
    color: $white
  }
}

.table__row {
  &:nth-child(odd) {
    background: rgba($gray, .2);
  }
}

.sortable__row {
  margin: 4px 0;
  cursor: move;
  &:first-child {
    margin-top: 0;
  }
  &:nth-child(odd) {
    background: rgba($gray, .2);
  }
  .table__row {
    &:nth-child(odd) {
      background: transparent;
    }
  }
}

.table__cell {
  height: 4rem;
  flex-grow: 1;
  text-align: left;
  align-items: center;
  padding: .5rem;
  display: flex;
  p {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: .875rem;
    font-weight: $semi-bold;
    color: $black-light;
  }
}

// Textarea
.lgt_textarea__bound {
  border-radius: .25rem .25rem 0px 0px;
  background: rgba(51, 51, 51, 0.06);
  position: relative;
  padding: 1.75rem .75rem .75rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.38);
}

.lgt_textarea {
  width: 100%;
  appearance: none;
  resize: none;
  border: 0;
  background: transparent;
  font-family: $font-family-2;
  font-size: .85rem;
  line-height: 150%;
  outline: 0;
  min-height: 15rem;
  max-height: 25rem;
  font-weight: $regular;
}
.lgt_textarea__advice {
  font-size: .75rem;
  margin-top: .25rem;
  color: rgba(0, 0, 0, 0.54);
  margin-bottom: 1.5rem;
  display: inline-block;
  padding-left: .75rem;
}

.lgt_textarea__label {
  position: absolute;
  top: .75rem;
  left: .75rem;
  font-size: .75rem;
  color: rgba(0, 0, 0, 0.54);
}


// Dropzone
.lgt_dropdown__header {
  border-radius: .25rem .25rem 0px 0px;
  height: 2.5rem;
  background: #CAC9CA;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 1rem;
  small {
    font-size: .75rem;
    color: #585858;
  }
  img {
    width: 1.5rem;
    display: inline-block;
    margin-left: .5rem;
  }
}
.lgt_dropzone {
  position: relative;
  >div {
    background: rgba(51, 51, 51, 0.06);
    position: relative;
    width: 100%;
    height: 80px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.38);
  }
}

.lgt_dropzone__form {
  position: relative;
  width: 100%;
  height: 100%;
}

.lgt_dropzone__label {
  position: absolute;
  font-size: .75rem;
  top: .5rem;
  left: .75rem;
  color: #585858;
  pointer-events: none;
}

.lgt_dropzone__input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: transparent url('./../../../assets/cloud.png')no-repeat center center;
  background-size: 3rem;
  border: 0;
  appearance: none;
  cursor: pointer;
  transition: all ease-in 100ms;

  input {
    position: absolute !important;
    height: 1px; width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
  }

  &:hover {
    background: transparent url('./../../../assets/cloud-shadow.png')no-repeat center center;
    background-size: 3.6rem;
  }
}

.lgt_dropzone__summary {
  background: rgba(51, 51, 51, 0.1);
  position: relative;
  padding: 1.75rem .75rem;
}

.lgt_dropzone__ul {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 1rem;
  section {
    display: flex;
    align-items: center;;
    justify-content: center;
    width: 12rem;
    flex-wrap: wrap;
    flex-direction: column;
    img {
      width: 5rem;
      display: inline-block;
    }
    small {
      text-align: center;
    }
    code {
      line-height: 1.5;
    }
  }
}

.lgt_dropdown__bound {
  position: relative;
  margin-bottom: 2rem;
}

.__no_link {
  text-decoration: none;
}

.__download {
  display: flex;
  align-items: center;
}

.disabled {
  opacity: 0.25;
}
