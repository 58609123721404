@import './../settings.scss';
@import './../mixins.scss';

.__nav {
  background: $p100;
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 9999;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
}

.__header_wrap {
  width: calc(100% - 2.5rem);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.__user {
  display: flex;
  width: 40%;
  align-items: center;
  justify-content: flex-end;
  figcaption {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 100%;
    margin-right: 1rem;
    small,p {
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  img {
    height: 2.75rem;
    width: 2.75rem;
    object-fit: cover;
    border-radius: 50%;
    margin-left: 1rem;
    max-width: 100%;
  }
  .__avatar{
    font-weight: 600;
  }
}

.__logo {
  max-width: 6.25rem;
  width: 100%;

  img {
    width: 100%;
  }
}

// Header component
.__header {
  padding: 1rem;
  position: relative;
  max-height: 5rem;
  width: 100%;
  margin: 0 auto;
  @include flexbox();
  @include flex(0, 1, auto);
  @include flex-direction(row);
  @include justify-content(space-between);
  @include align-items(center);
  &--nostat {
    @include justify-content(space-between);
  }
}

// Navigation component
.__router {
  @include flexbox();
  @include flex(0, 1, auto);
  @include flex-direction(row);
  @include justify-content(center);
  @include align-items(center);
  @include flex-wrap(wrap);
  position: absolute;
  right: .5rem;
  height: 100%;
}
.__navlink {
  max-width: 4rem;
  color: $white;
  text-decoration: none;
  margin-left: .5rem;
  height: inherit;
  @include flexbox();
  @include flex(0, 1, auto);
  @include flex-direction(row);
  @include justify-content(center);
  @include align-items(center);
  @include flex-wrap(wrap);

  @media screen and (min-width: 1000px) {
    max-width: 8rem;
  }

  &--group {
    text-align: center;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
    min-height: 3rem;
  }
  &--active {
    border-bottom: 2px solid $white;
  }
  small {
    font-size: .5rem;
    text-align: center;
    line-height: 10px;
    color: $black;
    width: 100%;
    @media screen and (min-width: 1000px) {
      font-size: .75rem;
      line-height: 14px;
      margin-top: .5rem;
    }
  }
  svg {
    align-self: flex-start;
    margin-bottom: 2px;
  }
}

.__news {
  height: 4rem;
  width: 3.5rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: .5rem;
  position: relative;
  flex-wrap: wrap;
  text-decoration: none;
  cursor: pointer;
  padding-top: .5rem;
  img {
    margin: 0 auto;
    width: 2.3rem;
  }
  small, svg {
    transition: all ease-in 200ms;
    color: $gray;
  }
  small {
    font-size: .5rem;
  }
  &:hover {
    img {
      filter: sepia(1) saturate(2);
    }
    svg, small {
      color: $s100;
    }
  }
}

.__news_mexico {
  height: 4rem;
  width: 3.5rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: .5rem;
  position: relative;
  flex-wrap: wrap;
  text-decoration: none;
  cursor: pointer;
  padding-top: .5rem;
  img {
    margin: 0 auto;
    width: 3.4rem;
  }
  small {
    font-size: .5rem;
    color: $black;
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0.15rem;
    background: $black-light;
    transition: transform ease-in 150ms;
    transform: scaleY(0);
  }
  &:hover {
    &:before {
      transform: scaleY(1);
    }
  }
}

.__bg {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba($p120, .75);
  top: 0;
  pointer-events: none;
  left: 0;
  animation: fade-in 50ms ease-in;
  animation-fill-mode: forwards;
  z-index: 9999;
}

.__indicators, .__close {
  padding: 0;
  width: 2rem;
  height: 2rem;
  appearance: none;
  background: transparent;
  border: 0;
  cursor: pointer;
  > svg {
    transition: color ease-in 150ms;
    color: $s100;
  }

  &:hover {
    svg {
      color: $s120;
    }
  }
}

.__close {
  margin-right: 0;
}

.__menu {
  padding: 1rem;
  background: $p100;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  animation-delay: 100ms;
  animation: fade-in 200ms ease-in;
  animation-fill-mode: forwards;
  @media only screen and ( min-width: 48em ) {
    max-width: 24rem;
  }
}

.__menu_head {
  @include flexbox();
  @include flex(0, 1, auto);
  @include flex-direction(row);
  @include flex-wrap(wrap);
  @include justify-content(space-between);
  @include align-items(center);
  margin-bottom: .5rem;
  @media only screen and ( min-width: 48em ) {
    margin-bottom: 1rem;
  }
}

.__menu_item {
  padding: .5rem 0;
  @include flexbox();
  @include flex(0, 1, auto);
  @include flex-direction(row);
  @include flex-wrap(wrap);
  @include justify-content(flex-start);
  @include align-items(center);
  border-bottom: $p110 1px solid!important;
  cursor: pointer;
  >svg {
    color: $s100;
    margin-right: .5rem;
  }
  >h2 {
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: calc(100% - 2rem);
    font-weight: 500;
  }

  &:hover {
    svg {
      color: $s120;
    }
    h2 {
      color: $white;
    }
  }
}


.__filter {
  border-top: 1px solid rgba($p110, .5);
  padding: .5rem 1rem;
  position: relative;
  min-height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  >h2, button, span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.__retry_btn {
  cursor: pointer;
  text-decoration: underline;
  margin-left: .5rem;
}

.__dropdowns {
  flex-wrap: nowrap !important;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.__admin_title{
  width: calc(70% - 3.5rem);
}
.__title {
  width: calc(100% - 4rem);

  >p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  > small {
    display: block;
  }
}

.__decoration_none {
  text-decoration: none;
}

.__options {
  color: red;
  overflow-y: auto;
  max-height: calc(100vh - 4.5rem);

  &::-webkit-scrollbar {
    display: none;
  }

  li, button{
    border-bottom: 1px solid $black;
  }


  button:active,
  button:focus {
    outline-style: none;
}
}
.__indicator {
  width: 100%;

  @media screen and (min-width: 1000px) {
    width: 50%;
  }
}

.__errorIndicator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: .25rem;
}
