@import './../../settings.scss';

.__outer {
  position: relative;
  padding: 1rem 0;
}

.__outer_compact {
  padding: 0 0 1.5rem 0;
}

.__bound {
  background: #F4F6FC;
  display: flex;
  flex-wrap: wrap;
  padding: .75rem 1rem;
  border: $p110 1px solid;
  border-radius: .5rem;
  transition: all ease-in 150ms;
  position: relative;

  &:hover {
      border: $p120 1px solid;
  }
}

.__bound_compact {
  padding-bottom: 2px;
}

.__icon {
  color: #69B6FF;
  width: 32px;
  height: 32px;
  display: inline-block;
  margin-right: 1rem;
  padding: 2px;
  display: grid;
  place-content: center;

  &__eye {
      margin-right: 0;
  }

  svg {
      width: 100%;
  }
}

.__field {
  appearance: none;
  border: none;
  background: transparent;
  outline: 0;
  color: $black-light;
  width: calc(100% - 5rem);
  height: 2rem;

  &:placeholder {
      color: #666666;
  }
}

.__field_compact{
width: calc(100% - 2rem);
}

.__field_label {
  color: #666666;
  position: absolute;
  top: .25rem;
  left: 1rem;
}

.__err_helper {
  font-size:.75rem;
  color: $error;
  padding-left: 1rem;
  position: absolute;
  bottom: 0;
  left: 0;
}
