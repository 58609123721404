/* Accordion styles */
.tabs {
  overflow: hidden;
}

.tab {
  width: 100%;
  color: white;
  overflow: hidden;
}

.tab_label {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: .5em 0;
  background: #f7f7f7;
  font-weight: bold;
  cursor: pointer;
  align-items: center;
  border-bottom: 1px solid hsla(0, 0%, 90%, 1);
  margin-bottom: 1.5rem;
  /* Icon */
}

.tab_label p {
  color: #222;
  margin: 0;
  word-break: break-word;
}

.tab_label small {
  color: #222;
  margin: 0;
}

.tab_label:hover {
  background: #ebebeb;
}

.tab_label::after {
  position: absolute;
  right: 1rem;
  top: calc(50% - .5rem);
  content: "\276F";
  color: #222;
  font-size: .75rem;
  width: 1rem;
  height: 1rem;
  text-align: center;
  -webkit-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
}

.tab_content {
  max-height: 0;
  padding: 0 1em;
  color: #2c3e50;
  background: white;
  -webkit-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
  visibility: hidden;
  line-height: 1.25;
}
.tab_content p {
    word-break: break-word;
}
.tab-close {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  background: #2c3e50;
  cursor: pointer;
}

.tab-close:hover {
  background: #ebebeb;
}

.tab__check {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.tab__check:checked + .tab_label {
  background: #ebebeb;
}

.tab__check:checked + .tab_label::after {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.tab__check:checked ~ .tab_content {
  max-height: 200vh;
  padding: 1em;
  visibility: visible;
}
