@import 'styles/settings.scss';

.__excel_wrapper {
	max-width: calc(100% - 2rem) !important;
}

.__excel {
	border: 1px solid #B0CBEF;
	border-spacing: 0px;
	border-collapse: collapse;
	width: 100%;
	margin-bottom: 32px;

	& th {
		padding: 4px;
	}

	& td {
		height: 30px;
		background-color: white;
		font-size: 14px;
		padding: 4px;
		border: 1px solid #D0D7E5;
		vertical-align: middle;
		text-align: left;
	}

	.cell {
		cursor: cell;
		-webkit-user-select: none;  /* Chrome all / Safari all */
  	-moz-user-select: none;     /* Firefox all */
		-ms-user-select: none;      /* IE 10+ */
		user-select: none;          /* Likely future */
	}

	.selected {
		border: 1px double rgb(33, 133, 208);
    transition: none;
    box-shadow: inset 0 -100px 0 rgba(33, 133, 208, 0.15);
	}

	.__header {
		background-image: url('../../assets/excel-header-bg.png');
		border: 1px solid #9EB6CE;
		background-size: cover;
		text-align: center;
		height: 36px;

		> .__delete_col_btn, .__add_col_btn {
			display: none;
		}

		&:hover {
			> .__delete_col_btn, .__add_col_btn {
				display: inline;
			}
		}
	}
}

.__cols {
	&:hover {
		>span {
			transform: translateY(-4px);
		}
	}
}

.__message {
	margin-right: auto;
	align-self: center;
	text-align: left;
}

.__delete_row_btn {
	position: absolute;
	left: -12px;
	top: 1px;
}

.__row_index {
	position: relative;
	max-width: 20px;

	> .__delete_row_btn {
		display: none;
	}

	&:hover {
		> .__delete_row_btn {
			display: inline;
		}
	}
}


.__page_wrapper {
	margin: 1rem 0;
}
