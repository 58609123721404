.__alert {
  padding: 10px;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.03);
  width: 300px;
  box-sizing: border-box;

  > span {
    padding-left: 12px;
  }
}

.__btn {
  border: none;
  background-color: transparent;
  cursor: pointer
}
