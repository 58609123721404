@import './../../../styles/settings.scss';

.__error {
  padding: .5rem 0;
  background: $error;
  text-align: center;
  border-radius: .25rem;
  margin: 1rem 0;

  p {
    color: $white;
    font-size: .875rem;
    line-height: 125%;
    font-weight: 400;
  }
}

.__ok {
  padding: .5rem 0;
  background: $go;
  text-align: center;
  border-radius: .25rem;
  margin: 1rem 0;

  p, a {
    color: $white;
    font-size: .875rem;
    line-height: 125%;
    font-weight: 400;
  }
}

