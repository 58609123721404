@import './../settings.scss';
@import './../mixins.scss';

.__white {
  color: $white;
}

.__ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  display: inline-block;
}

.__gray {
  color: $gray;
}

.__black {
  color: $black;
}

.__black__light {
  color: $black-light;
}

.__right {
  text-align: right;
}

.__center {
  text-align: center;
}
.__justify {
  text-align: justify;
}


.__primary {
  color: $p100;
}

.__primary__light {
  color: $p110;
}

.__uppercase {
  text-transform: uppercase;
}

.__strong {
  font-weight: $medium
}

.__light {
  font-weight: $regular
}

.__small {
  @include fluid-type(.75rem, .875rem);
	@include fluid-height(1rem, 1rem);
}
