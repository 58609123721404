@import './../settings.scss';
@import './../mixins.scss';

.__title {
    margin-bottom: 1rem;
    @include flexbox();
    @include flex(0, 1, auto);
    @include flex-direction(row);
    @include flex-wrap(nowrap);
    @include justify-content(flex-start);
    width: 100%;
    color: $black;
    div {
        margin-right: .5rem;
        margin-top: .25rem;

        @media screen and (min-width: 1000px){
          margin-top: .75rem;
        }
    }
}

.__stitle {
  margin: 1rem 0;
  @include flexbox();
  @include flex(0, 1, auto);
  @include flex-direction(row);
  @include flex-wrap(nowrap);
  @include justify-content(flex-start);
  @include align-items(center);
  color: $black;
  text-transform: capitalize;
  svg {
      margin-right: .5rem;
  }
}

.__description {
    padding: 1.5rem .5rem;
    position: relative;
}

.__seemore {
  position: absolute;
  bottom: 0;
  right: 0;
  text-decoration: none;
  background: $p100;
  color: $white;
  border-radius: 1rem;
  padding: 0 .5rem 0 1rem;
  height: 2rem;
  align-items: center;
  width: 100px;
  display: block;
  small {
    color: $gray;
    font-size: 10px;
    float: left;
    margin-top: .5rem;
  }
  svg {
    margin-top: .45rem;
    margin-left: .5rem;
  }
  @media screen and (max-width: 330px){
    padding: 0 .5rem;
    width: auto;
    small {
      display: none;
    }
    svg {
      margin-left: 0;
    }
  }
  @media screen and (min-width: 680px){
    display: none;
  }
}

.__formula {
    border-radius: .25rem;
    background: $gray;
    padding: .5rem;
    width: 100%;
    max-width: 35rem;

    small,
    code {
        width: 100%;
        display: block;
    }

    code {
      margin-bottom: 1rem;
    }
}

.__sources {
  border-radius: .25rem;
  background: $gray;
  padding: .5rem;
  width: 100%;
  max-width: 35rem;

  small {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: .5rem;
      &:last-child {
        margin-bottom: 0rem;
      }
      svg {
        margin-right: .25rem;
      }
  }

  span {
    font-weight: 100;
  }

  ul > li {
    padding: 4px 0;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
  a {
    color: $p100;
  }
}

.__summary {
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
    color: $black;
}

.__no_indicators {
  padding-bottom: 3rem;
  p {
    color: $black-light;
    margin-bottom: 1rem;
  }
}

.__cover {
  max-height: 50vh;
  object-fit: cover;
  object-position: center;
}

.__slider {
  padding: 3rem 12px;
}

.__fadein {
  -webkit-animation: fadein 2s;
  animation: fadein 2s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.__content_container {
  width: 100%;
  padding: 16px 12px 0 12px;
  > div {
    box-sizing: initial;
    line-height: initial;
    height: initial;
    outline: initial;
    overflow-y: initial;
    padding: initial;
    tab-size: initial;
    -moz-tab-size: initial;
    text-align: initial;
    white-space: initial;
    word-wrap: initial;
  }
}

.__content {
  width: 100%;
}

.__no_data {
  margin-bottom: 2rem;

  @media screen and (min-width: 1000px){
    margin-bottom: 0rem;
  }
}

.__csv_link {
  text-decoration: none;
   color: #4c5053;
  display: inline-flex;
   align-items: center;
   border: 1px solid #4c5053;
   padding: 1rem;
   border-radius: 4px;
   margin-top: 1rem;

   img {
    margin-right: .5rem;
   }
}

.__formula_img {
  width: 100%;
  display: inline-block;
}
