@import './../settings.scss';

.__canvas {
	padding-top: 7.7rem;
	background: #FFF;
	min-height: 100vh;
	&--nostats {
		padding-top: 4rem;
	}
	@media screen and (min-width: 1000px) {
		padding-top: 128px;
		&--nostats {
			padding-top: 4rem;
		}
	}

}

.__block {
	padding: 1rem 0;
	border: 1px solid $p120;
	text-align: center;
	margin: .5rem 0;
}


