@import '../settings.scss';

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($p100, 0.75);
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal__close {
  appearance: none;
  background: transparent;
  border: none;
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  right: 1rem;
  cursor: pointer;
}

.modal__card {
  max-width: 640px;
  width: 100%;
  background: $white;
  border-radius: .25rem;
  position: absolute;
  padding: .5rem 1rem 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  h1 {
    font-size: 2rem;
    text-align: center;
    border-bottom: rgba($gray, .3) 1px solid;
    padding-bottom: .5rem;
    color: $black;
  }
  p {
    padding: 1rem 0;
    text-align: center;
    color: $black-light;
  }
}

.modal__body {
  ul {
    max-height: 80vh;
    overflow-y: auto;
    li {
      padding: 8px 0;
    }
  }
}

.modal__buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  section {
    margin-right: 1rem;
    width: 240px;
  }
}
