@import './../settings.scss';
@import './../mixins.scss';


.__g_chart {
  @include v-rythm(1.5rem, 3rem);
}

.__header {
  padding: .75rem .5rem;
  background: $p100;
  border-radius: .5rem .5rem 0px 0px;
  width: 100%;
  display: flex;
  > small {
    align-self: center;
  }

  > button {
    margin-left: auto;
  }
}

.__charts {
  border: 1px solid #BFBFBF;
  border-radius: .5rem;
}

.__abreviations {
  padding: 4px 0 8px 0;
  width: 100%;
}

.__abreviations_btn {
  background: $p100;
  border: none;
  border-radius: 16px;
  height: 36px;
  width: 72px;
  cursor: pointer;

  &:hover {
    background: $p120;
  }
}

.__pie {
  border-bottom: 1px solid #BFBFBF;
  margin-bottom: 1rem;
  width: 100%;
  position: relative;

  &_border {
    border-bottom: 1px solid #BFBFBF;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
  }
}

.__chart_head {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    width: 100px;
    height: 5px;
    top: 67px;
    left: 0;
    background: #fff;
    @media screen and (min-width: 1000px ) {
      top: 76px;
    }
  }
}


.no_data_states {
  padding: .5rem .5rem;
  width: 100%;
  margin-bottom: .5rem;
  border-bottom: 1px solid #BFBFBF;
  &_table{
    padding: .5rem .5rem;
  width: 100%;
  margin-top: .5rem;
  border-top: 1px solid #BFBFBF;
  }
  &__head {
    color: $p100;
    display: flex;
    align-items: center;
    margin-bottom: .5rem;
    svg {
      margin-right: .5rem;
    }
  }
  &__list {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  &__badge {
    max-width: 180px;
    margin: .25rem .25rem .25rem 0;
    padding: .25rem .5rem;
    background: $s100;
    border-radius: .25rem;
  }
}
