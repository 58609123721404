@import "styles/settings.scss";

.lgt_image_uploader {
  .fileContainer {
    position: relative;
    padding: 0;
    border-bottom: 0;
    margin: 0;
    background: rgba(51, 51, 51, 0.06);
    box-shadow: none;
    .lgt_image_uploader__button {
      border-bottom: 1px solid rgba(0, 0, 0, 0.38);
      height: 4rem;
      line-height: 4rem;
      border-radius: 0.25rem 0.25rem 0px 0px;
      width: 100%;
      background: rgba(51, 51, 51, 0.06) url("./../../../assets/cloud.png")
        no-repeat calc(100% - 1rem) calc(50%);
      background-size: 1.5rem;
      color: rgba(0, 0, 0, 0.38);
      text-align: left;
      margin: 0;
      &:disabled {
        cursor: not-allowed !important;
      }
    }
  }
}

.lgt_image_uploader__label {
  font-size: 0.75rem;
  margin-top: 0.25rem;
  color: rgba(0, 0, 0, 0.54);
  margin-bottom: 1.5rem;
  display: inline-block;
  padding-left: 0.75rem;
}

.ql-toolbar.ql-snow {
  margin-top: 4px;
  padding: 2px;
}

.ql-container.ql-snow {
  border: none;
}

.ql-container.ql-snow > .ql-editor {
  max-height: 20rem;
  overflow: auto;
  padding: 12px 0;
}

.md-editor {
  word-break: break-all;

  & blockquote {
    display: block;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 40px;
    margin-right: 40px;
  }

  & code {
    background-color: #f5f5f5;
    border-radius: 0;
    padding: 3px 0;
    margin: 0;
    font-size: 14px;
    overflow-x: auto;
    word-break: normal;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  & pre {
    display: block;
    background-color: #f5f5f5;
    padding: 20px;
    font-size: 14px;
    line-height: 28px;
    border-radius: 0;
    overflow-x: auto;
    word-break: break-word;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  & img {
    max-width: 100%;
  }
  & ul {
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;

    & li {
      list-style: disc !important;
    }
  }

  & ol {
    display: block;

    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
  }
  & li {
    list-style: decimal !important;
  }
}
.md-editor table {
  width: 100%;
  font-size: 14px;
  line-height: 1.7;
  max-width: 100%;
  overflow: auto;
  border-collapse: collapse;
  border-spacing: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.125rem;
  margin: 1rem 0;

  & thead,
  tbody {
    border-radius: 4px;
    width: 100%;
  }
  & tr {
    width: 100%;
    display: flex;

    & th {
      box-sizing: border-box;
      flex: 1;
      padding: 0.8em 1.2em;
      overflow: hidden; // Or flex might break
      text-align: left;
      padding: 10px 15px;
      word-break: break-word;
    }

    & td {
      box-sizing: border-box;
      flex: 1;
      padding: 0.8em 1.2em;
      overflow: hidden; // Or flex might break
      border: 1px solid #efefef;
      text-align: left;
      padding: 10px 15px;
      word-break: break-word;
      line-height: 1.25;
    }
  }

  p {
    margin-bottom: 0;
  }
}

.rc-md-editor.full {
  padding-top: 120px !important;
}

.__header {
  vertical-align: middle;
  span {
    margin: 0 0.5rem;
    transform: translateY(2px);
    display: inline-block;
  }
  button {
    appearance: none;
    border: none;
    padding: 0;
    height: 1.5rem;
    width: 1.5rem;
    transform: translateY(2px);
    border-radius: 0.75rem;
    z-index: 1;

    &.__danger {
      background: $error;

      &:hover {
        background: $error110;
      }
    }

    &.__success {
      background: $go;

      &:hover {
        background: $go110;
      }
    }

    cursor: pointer;
    svg {
      width: 1rem;
      stroke: $white;
    }
  }
}

.__excel__drop {
  appearance: none;
  max-width: 240px;
  margin-left: 1rem;
  width: 100%;
  padding: 0.5rem;
  border-radius: 0.25rem;
  background: #fff url("./../../../assets/arr-down.png") no-repeat right center;
  background-size: 1.5rem;
}

// Styles for items

.md-editor {
  * {
    white-space: initial;
    word-break: break-word;
  }

  a[name^="_ftn"] {
    text-decoration: none;
    color: $p100;
    vertical-align: super;
    font-size: .75rem;
  }

  div[id^="ftn"] {
    a {
      color: $black-light;
    }
    p {
      font-size: .8rem;
    }
  }
}
