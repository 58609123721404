@import './mixins.scss';
// Set the number of columns you want to use on your layout.
$flexboxgrid-grid-columns: 12 !default;
// Set the gutter between columns.
$flexboxgrid-gutter-width: 1rem !default;
// Set a margin for the container sides.
$flexboxgrid-outer-margin: 2rem !default;
// Create or remove breakpoints for your project
// Syntax:
// name SIZErem,
$flexboxgrid-breakpoints:
  sm 48em 46rem,
  md 62em 61rem,
  lg 75em 71rem !default;
$flexboxgrid-max-width: 1180px !default;

//
// -- Stop editing -- //
//

$gutter-compensation: $flexboxgrid-gutter-width * .5 * -1;
$half-gutter-width: $flexboxgrid-gutter-width * .5;

.__wrapper {
  box-sizing: border-box;
  max-width: $flexboxgrid-max-width;
  margin: 0 auto;
  width: 100%;
}

.__wrapper--indicators {
  max-width: 1180px;
  width: 100%;
}

.__container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-right: $flexboxgrid-outer-margin;
  padding-left: $flexboxgrid-outer-margin;
}

.__row {
  box-sizing: border-box;
  @include flexbox();
  @include flex(0, 1, auto);
  @include flex-direction(row);
  @include flex-wrap(wrap);
  // margin-right: $gutter-compensation;
  // margin-left: $gutter-compensation;
}

.row.reverse {
  @include flex-direction(row-reverse);
}

.__col.reverse {
  @include flex-direction(column-reverse);
}

@mixin flexboxgrid-sass-col-common {
  box-sizing: border-box;

  // split @include flex(0, 0, auto) into individual props
  @include flex-grow(0);
  @include flex-shrink(0);

  // we leave @include flex-basis(auto) out of common because
  // in some spots we need it and some we dont
  // more why here: https://github.com/kristoferjoseph/flexboxgrid/issues/126

  padding-right: $half-gutter-width;
  padding-left: $half-gutter-width;
}

$name: xs;
.__col_#{$name} {
  @include flexboxgrid-sass-col-common;
  @include flex-basis(auto);
}
@for $i from 1 through $flexboxgrid-grid-columns {
  .__col_#{$name}_#{$i} {
    @include flexboxgrid-sass-col-common;
    @include flex-basis(100% / $flexboxgrid-grid-columns * $i);
    max-width: 100% / $flexboxgrid-grid-columns * $i;
  }
}
@for $i from 0 through $flexboxgrid-grid-columns {
  .__col_#{$name}_offset_#{$i} {
    @include flexboxgrid-sass-col-common;
    @if $i == 0 {
      margin-left: 0;
    } @else {
      margin-left: 100% / $flexboxgrid-grid-columns * $i;
    }
  }
}
.__col_#{$name} {
  @include flex-grow(1);
  @include flex-basis(0);
  max-width: 100%;
}
.start_#{$name} {
  @include justify-content(flex-start);
  text-align: left;
}

.center_#{$name} {
  @include justify-content(center);
  text-align: center;
}

.end_#{$name} {
  @include justify-content(flex-end);
  text-align: right;
}

.top_#{$name} {
  @include align-items(flex-start);
}

.middle_#{$name} {
  @include align-items(center);
}

.bottom_#{$name} {
  @include align-items(flex-end);
}

.around_#{$name} {
  @include justify-content(space-around);
}

.between_#{$name} {
  @include justify-content(space-between);
}

.first_#{$name} {
  order: -1;
}

.last_#{$name} {
  order: 1;
}


@each $breakpoint in $flexboxgrid-breakpoints {
  $name: nth($breakpoint, 1);
  $size: nth($breakpoint, 2);
  $container: nth($breakpoint, 3);
  @media only screen and (min-width: $size) {
    .container {
      width: $container;
    }

    .__col_#{$name} {
      @include flexboxgrid-sass-col-common;
      @include flex-basis(auto);
    }
    @for $i from 1 through $flexboxgrid-grid-columns {
      .__col_#{$name}_#{$i} {
        @include flexboxgrid-sass-col-common;
        @include flex-basis(100% / $flexboxgrid-grid-columns * $i);
        max-width: 100% / $flexboxgrid-grid-columns * $i;
      }
    }
    @for $i from 0 through $flexboxgrid-grid-columns {
      .__col_#{$name}-offset_#{$i} {
        @include flexboxgrid-sass-col-common;
        @if $i == 0 {
          margin-left: 0;
        } @else {
          margin-left: 100% / $flexboxgrid-grid-columns * $i;
        }
      }
    }
    .__col_#{$name} {
      @include flex-grow(1);
      @include flex-basis(0);
      max-width: 100%;
    }
    .start_#{$name} {
      @include justify-content(flex-start);
      text-align: left;
    }

    .center_#{$name} {
      @include justify-content(center);
      text-align: center;
    }

    .end_#{$name} {
      @include justify-content(flex-end);
      text-align: right;
    }

    .top_#{$name} {
      @include align-items(flex-start);
    }

    .middle_#{$name} {
      @include align-items(center);
    }

    .bottom_#{$name} {
      @include align-items(flex-end);
    }

    .around_#{$name} {
      @include justify-content(space-around);
    }

    .between_#{$name} {
      @include justify-content(space-between);
    }

    .first_#{$name} {
      order: -1;
    }

    .last_#{$name} {
      order: 1;
    }
  }
}
