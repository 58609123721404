.__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  position: relative;
  align-items: flex-start;
  margin-bottom: 1.5rem;
}

.__container {
  width: calc(50% - 1rem);
  margin: .5rem .5rem;
  padding: 10px;
  background: #edf2f6;
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
  border: 1px solid #d0dbe4;
  position: relative;
}

.__remove {
  background: red;
  width: 1.5rem;
  height: 1.5rem;
  font-size: .8rem;
  line-height: 1.6rem;
  font-weight: bold;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: -9px;
  right: -9px;
  color: #fff;
}

.__image {
  width: 100%;
}
