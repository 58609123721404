// Typography
$font-family: 'PT Sans', sans-serif;
$font-family-2: 'Open Sans', sans-serif;
$code: 'Roboto Mono', monospace;
$regular: 400;
$medium: 500;

// Colors
$p100: #314354;
$p110: #5c6e80;
$p120: #081c2b;

$s100: #F9EA92;
$s110: #ffffc3;
$s120: #c5b863  ;

$black: #000;
$black-light: #666;
$white: #FFFFFF;
$gray: #eaeaea;

//Titles
$h1: 2rem;
$h2: 1.5rem;
$h3: 1.25rem;
$h4: 1.125rem;
$p: 1rem;
$subtitle: .875rem;
$subtitle_small: .75rem;
$small: .625rem;
$bold: 700;
$semi-bold: 600;
$regular: 400;

$hover: all ease-in 150ms;
$appear: all ease-in 300ms;

$error: #F31C1C;
$error110: #AA1313;
$error120: #F77676;
$go:#2e7d32;
$go110: #005005;
$go120: #60ad5e;

$_h1: 3rem;
