@import './../settings.scss';
@import './../mixins.scss';

.__table {
  padding: 0 .5rem;
  @include v-rythm(3rem, 3rem);
}


.__stitle {
  margin-top: 1rem;
  margin-bottom: 1rem;
  @include flexbox();
  @include flex(0, 1, auto);
  @include flex-direction(row);
  @include flex-wrap(nowrap);
  @include justify-content(flex-start);
  @include align-items(center);
  color: $black;
  svg {
      margin-right: .5rem;
  }
}


// Table

.__title {
  margin-right: auto;
}

.__data_dw {
  appearance: none;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  display: inline-block;
  background: transparent;
  border: 0;
  margin-left: 1rem;
  border-radius: .5rem .5rem 0px 0px;
  cursor: pointer;
  img {
    width: auto;
    height: 1.5rem;
  }
}
.__download {
  border-radius: .5rem .5rem 0px 0px;
  padding: .5rem .5rem;
  @include flexbox();
  @include flex(0, 1, auto);
  @include flex-direction(row);
  @include flex-wrap(nowrap);
  @include justify-content(flex-start);
  @include align-items(center);
}



.__table_C {
  width: 100%;
  border-radius:.5rem;
  border: 1px solid #BFBFBF;
}

.__table_head {
  background: $p100;
  display: flex;
  align-items: center;
   flex-direction: row;
   flex-grow: 0;
   flex-wrap: nowrap;
   width: 100%;
   border-top-left-radius: .5rem;
   border-top-right-radius: .5rem;
}

.__table_row {
  display: flex;
  align-items: center;
   flex-direction: row;
   flex-grow: 0;
   flex-wrap: nowrap;
   width: 100%;
   border-bottom: $gray 1px solid;
   &:first-child {
     background: red;
   }
   &:last-child {
    border-radius: 0px 0px .5rem .5rem;
    border-bottom: none;
   }
  &:hover {
    background: #BFBFBF;
  }
}

.__table_foot {
  background: $p100;
  display: flex;
  align-items: center;
  justify-content: center;
   flex-direction: row;
   flex-grow: 0;
   flex-wrap: nowrap;
   width: calc(100% - 2rem);
   padding: 1rem 0;
   margin: 1rem auto 0;
   max-width: 340px;
   border-radius: .5rem;
   cursor: pointer;
   transition: all 150ms ease-in;
   margin-bottom: 1rem;
  &:hover {
    background: $p120;
  }

}


.__num {
  width: 2.5rem;
}

.__txt {
  width: 8rem;
  flex-grow: 1;
}

.__head_cell, .__row_cell {
  padding: 1rem .5rem;
  small {
    width: 100%;
    display: inline-block;
  }
}


.__details {
  padding: 1rem .5rem;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.__indicator {
  margin-right: .5rem;
  margin-bottom: .5rem;
  small {
    display: inline;
  }
  p {
    width: 100%;
  }
}

.__indicator_title {
  width: 100%;
  margin-bottom: .5rem;
}

.__close {
  appearance: none;
  background: transparent;
  border: 0;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 1rem;
  right: .5rem;
  padding: 0;
  svg {
    color: $p100;
  }
}

.__spinner {
  margin-left: auto;
}

.fedAmount {
  padding: .5rem 0;
  width: 100%;
}


.card {
  border-radius: .25rem;
  background: $p110 url('./../../assets/wp-icon--card.png')no-repeat;
  background-position: top right;
  background-size: 5rem;
  padding: .5rem;
  small, h2 {
    width: 100%;
    display: block;
  }
}
