@import './../settings.scss';
@import './../mixins.scss';

.__select {
  appearance: none;
  height: 2rem;
  width: 100%;
  border-radius: .5rem;
  border: 1px solid $p100;
  background: $gray url('./../../assets/arr-down.png')no-repeat;
  background-position: calc(100% - .5rem) center;
  background-size: 1.5rem;
  padding: 0 2.5rem 0 .5rem;
  text-align-last: center;
  font-family: $font-family;
  font-weight: $medium;
  font-size: .875rem;
  color: $p100;
  border: 0;
  animation: enable-animation 500ms;
  cursor: pointer;
  transition: background ease-in 150ms;
  &:hover {
    background: #253546 url('./../../assets/arr-down-white.png')no-repeat;
    background-position: calc(100% - .5rem) center;
    background-size: 1.5rem;
    color: $white;
  }

  @keyframes enable-animation {
    0% {
      background: $p110;
    }
    100% {
      background: $white;
    }
  }

  &:disabled {
    background-color: $p110;
    animation: disable-animation 500ms;

    @keyframes disable-animation {
      0% {
        background: $white;
      }
      100% {
        background: $p110;
      }
    }
  }
}

.__transparent {
  color: $p120;
  background-size: 1.5rem;
  padding-left: 1rem;
  height: 2rem;
  text-align-last: left;
  cursor: pointer;
  max-width: 300px;
  outline: 0;
  transition: background ease-in 150ms;
}

.__option {
  text-align: center;
}

.__hide {
  position:absolute;
  left:-10000px;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
}

.__animation {
  transition: all 0.5s ease-in-out;
}
