@import './settings.scss';
@import './mixins.scss';

// Reset

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}

* {
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	line-height: 1;
	font-family: $font-family;
	font-size: 16px;
	color: $black;
	font-weight: $medium;
	min-height: 100vh;
}
ol, ul, li {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

// Importing G font
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600|PT+Sans:700&display=swap');

@font-face {
	font-family: 'Roboto Mono';
	src: url('./../assets/fonts/RobotoMono-Regular.woff2') format('woff2'),
			url('./../assets/fonts/RobotoMono-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}



h1, h2 {
	font-family: $font-family;
	font-weight: 700;
}

p, small {
	font-family: $font-family-2;
	font-weight: 400;
	white-space: pre-wrap;
}

h1 {
	@include fluid-type(1.5rem, 2.5rem);
	@include fluid-height(2rem, 3rem);
}

h2 {
	@include fluid-type(1.125rem, 1.125rem);
	@include fluid-height(1.5, 1.5);
}

p {
	@include fluid-type(.875rem, 1.125rem);
	line-height: 1.5;
}

small {
	@include fluid-type(.8rem, .9rem);
	@include fluid-height(1rem, 1rem);
}

code {
	@include fluid-type(.75rem, .875rem);
	@include fluid-height(1.5rem, 2.5rem);
	font-family: $code;
	font-weight: 400;
}

strong {
	font-weight: $bold;
}

em {
	font-style: italic;
}


.leaflet-top {
	margin-top: 2rem;
}

.leaflet-container {
	height: calc(85vh - 124px);
	@media screen and (min-width: 780px){
		height: calc(95vh - 124px);
	}
	@media screen and (min-height: 780px){
		height: calc(85vh - 124px);
	}
}
