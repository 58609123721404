@import './../settings.scss';

.lgt_admin {
  min-height: calc(100vh - 60px);
  padding-top: 7rem;
  max-width: calc(100% - 2rem)!important;
}

.no_bottom {
  min-height: initial;
}

.lgt_head {
  margin-bottom: 2rem;
  h2 {
    margin-top: 1rem;
  }
}

.__footer {
  padding-top: 16px;
  padding-bottom: 16px;

  > span {
    padding: 12px 0px;
    color: $black-light;
  }
}

.__lgt_form_errors {
  font-size: .75rem;
  padding-right: 0.5rem;
  color: $error;
}

.__form_errors {
  border-radius: 4px;
  background-color: #F8D7DA;
  width: 100%;
  padding: 12px;
  margin: 8px 0px;
  color: #721C24;

  & ul > li {
    padding: 4px 0px;
  }
}

.__icon_wrapper {
  display: flex;
  justify-content: center;
  section {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;;
    justify-content: center;
    img {
      width: 10rem;
      display: inline-block;
    }
    small {
      text-align: center;
    }
  }
}

.__remove {
  position: absolute;
  top: 0;
  right: 0;
  padding: 2px;
  border: none;
  background-color: $error;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  cursor: pointer;
}

.__remove:hover {
  background-color: #b80f0a;
}

.__items_dropdown {
  margin: 8px 0 !important;
}


.__csv_remove {
  position: absolute;
  top: 0;
  left: 110px; appearance: none;
  background: red;
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 32px; padding: 0;
  cursor: pointer;
  svg {
    margin-top: 4px
  }
}

.__csv_link {
  display: inline-block;
  svg {
    margin-left: .25rem;
  }
}

.__csv_container {
  padding: 1rem;
}

.__csv_row {
  position: relative
}
