@import './../settings.scss';
@import './../mixins.scss';

.__buttons {
  position: fixed;
  z-index: 99999;
  width: 100%;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  & div {
    cursor: pointer;
  }

  @media only screen and ( min-width: 1000px ) {
    width: 32px;
    right: 0;
    top: 10rem;
    flex-direction: column;
    margin-right: 4px;
  }
}
