@import './../settings.scss';
@import './../mixins.scss';

.__transparent {
  background-color: transparent;
  border-width: 0;
}

.lgt_button__bound {
  padding: 1rem 0;
}

.lgt_button {
  appearance: none;
  border: none;
  border-radius: .25rem;
  padding: .625rem .75rem;
  font-size: 14px;
  text-transform: uppercase;
  color: $white;
  font-weight: $regular;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24);
  cursor: pointer;
  transition: all ease-in 200ms;
  width: 100%;
  &:hover {
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.25);
  }
  &:disabled {
    cursor: not-allowed;
    box-shadow: initial;
  }
}

.__primary {
  background: $p100;

  &:hover {
    background: $p120;
  }

  &:disabled {
    background-color: $s110;
  }
}

.__danger {
  background: $error;

  &:hover {
    background: $error110;
  }

  &:disabled {
    background: $error120;
  }
}

.__success {
  background: $go;

  &:hover {
    background: $go110;
  }

  &:disabled {
    background: $go120;
  }
}

.__login_button {
  height: 3rem;
  border-radius: .5rem;
  outline: 0;
}
