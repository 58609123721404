.thumbs-wrapper {
  display: flex;
  justify-content: center;
}

.carousel .slide {
  background: transparent !important;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
  display: flex;
  justify-content: center;
}
