@import './../settings.scss';
@import './../mixins.scss';

.__loader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba($white, .9);
  top: 0;
  left: 0;
  z-index: 99999999;
}

.__message {
  position: absolute;
  width: 100%;
  top: calc(50% + 2.5rem);
  text-align: center;
}

.__dots {
  width: 3em;
  height: 3em;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  align-items: center;
  position: absolute;
  top: calc(50% - 1.5em);
  left: calc(50% - 1.5em);
}

.__dots > div {
  width: 0.5em;
  height: 0.5em;
  background-color: $s100;
  border-radius: 50%;
  animation: fade 500ms alternate ease-in-out infinite;
}

.__dots > div:nth-of-type(2),
.__dots > div:nth-of-type(4) {
  animation-delay: 0.25s;
}

.__dots > div:nth-of-type(3),
.__dots > div:nth-of-type(5),
.__dots > div:nth-of-type(7) {
  animation-delay: 0.5s;
}

.__dots > div:nth-of-type(6),
.__dots > div:nth-of-type(8) {
  animation-delay: 0.75s;
}

.__dots > div:nth-of-type(9) {
  animation-delay: 1s;
}

@keyframes fade {
  to {
    opacity: 0.2;
  }
}
