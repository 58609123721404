@import './../../../styles/settings.scss';

.__logo {
  margin: 1.5rem 0;
  img {
    height: 5rem;
    width: auto;
  }
}

.__forgot{
  padding: 1rem 0;
}

.__right{
  text-align: right;
  color: #4355a2;
  font-size: .75rem;
}

.__middle{
  color: #4355a2;
  font-size: .75rem;
}

.__footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 3rem;
  text-align: center;
  background: $p100;
  display: grid;
  place-content: center;
  &__text {
    color: $black;
    font-size: 1rem;
    font-weight: 600
  }
}


