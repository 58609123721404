@import './../settings.scss';

.lgt_input__bound {
  border-radius: .25rem .25rem 0px 0px;
  background: rgba(51, 51, 51, 0.06);
  position: relative;
  padding: .75rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.38);
}

.lgt_input__label {
  position: absolute;
  top: .75rem;
  left: .75rem;
  font-size: .75rem;
  color: rgba(0, 0, 0, 0.54);
}

.lgt_input,
.lgt_dropdown {
  background: transparent;
  font-size: 1rem;
  width: 100%;
  border: none;
  margin-top: 1rem;
  color: rgba(0, 0, 0, 0.87);
  appearance: none;
  border: none;
  outline: 0;
}

.lgt_dropdown {
  background: transparent url('./../../assets/dropdown.png')no-repeat right 100%;
  background-size: 1.5rem;
}


.lgt_input__advice {
  font-size: .75rem;
  margin-top: .25rem;
  color: rgba(0, 0, 0, 0.54);
  margin-bottom: 1.5rem;
  display: inline-block;
  padding-left: .75rem;
}

.lgt_input__error {
  font-size: .75rem;
  position: relative;
  left: .75rem;
  color: $error;
}
