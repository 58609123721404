@import './../settings.scss';

.info {
    padding: 8px;
    background: white;
    border-radius: 5px;
    box-shadow: 0 0 5px #555;
}

.total {
    background: $p100;
    color: $white;
    font-size: 16px;
    font-family: $font-family-2;
}

.total--state {
  background: $p110;
  color: $white;
  font-size: 16px;
  font-family: $font-family-2;
}

.total--ese {
  background: #F9EA92;
  color: $black;
}

/**
 * Make own block so the autoprefixes only ignores
 * this one.
 */
.webkit-box-orient {
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
}

.legend {
    line-height: 18px;
    color: #555;
    max-width: 130px;

    >p {
      // Moved -webkit-box-orient to its own block
      display: block;
      word-break: break-word;
      font-weight: $medium;
      font-size: 12px;
      line-height: 1.25;
      margin-bottom: 6px;
    }

    @media screen and (min-width: 680px){
      max-width: 340px;
      > p {
        font-size: 14px;
      }
    }
}

.legend i {
    width: 18px;
    height: 18px;
    float: left;
    margin-right: 8px;
    opacity: 0.7;
}

.legend span {
  font-size: 12px;

  @media screen and (min-width: 680px){
    font-size: 13px;
  }
}

.popup-title {
    font-weight: bold;
}

.leaflet-container path{
    transition: stroke 1s;
    transition: fill 1s;
}
