@import './../settings.scss';

.frame {
  position: fixed;
  width: 100%;
  height: 100%;
  background: $p100;
  z-index: 9999999;
  align-items: center;
  justify-content: center;
  display: flex;
  img {
    width: 100px;
    display: inline-block;
    margin: 0 auto;
  }

  @media (min-width: 960px) and (max-width: 2600px) and (orientation: landscape) {
    display: none;
  }
}

.holder {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 420px;
  width: 100%;

  figcaption {
    padding: 0 1rem;
    text-align: center;
  }
}
