@import './../settings.scss';

.__container {
  height: 100vh !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.__text {
  text-align: center;
}

.__logo {
  padding-bottom: 2rem;
  width: 15rem;
}
